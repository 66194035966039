import React from "react"

const brandwebsite = () => {
  return (
    <section className="exclusive">
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <p className="services-digital">
              Get your brand in the limelight with beautiful visual content!
            </p>
            <p className="story-text">
              Get an excellent and functional website along with leveraging the
              power of efficient mobile app development. We are good at creating
              iOS &amp; Android mobile applications so that you can reach out to
              people through their favorite devices. Our brilliant team ensures
              agility, robust development infrastructure and on-time deliveries
              that makes us a favorite among the customers. Gaining customer
              trust is our biggest asset and we strive to deliver nothing but
              the best.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default brandwebsite
