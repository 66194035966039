import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"

const websiteservices = () => {
  return (
    <>
      <section className="exclusive py-4">
        <div className="container">
          <h1 className="page-title website-graphic">
            Website Design &amp; Development
          </h1>
          <p className="website-create">
            Let our experts create your dream website for you
          </p>
          <div className="row">
            <div className="col-12 col-lg-4">
              <ul className="service-graphic">
                <li>Small Business Website</li>
                <li>Corporate Website</li>
                <li>e-commerce website</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic">
                <li>WordPress Website</li>
                <li>CMS Web Development</li>
                <li> Website Maintenance &amp; Support</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="exclusive">
        <div className="container">
          <h1 className="page-title website-graphic">
            mOBILE aPPLICATION dESIGN &amp; dEVELOPMENT
          </h1>
          <p className="website-create">
            We create simple, clean and creative mobile app designs for your
            business
          </p>
          <div className="row">
            <div className="col-12 col-lg-4">
              <ul className="service-graphic">
                <li>Android Application </li>
                <li>IOS Application</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <ul className="service-graphic">
                <li>Hybrid application</li>
                <li>Windows application</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/photography-videography">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Photography &amp; Videography
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/digital-marketing">
                {" "}
                <span>
                Digital Marketing{" "}
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>
      
          </div>
        </div>
      </section>
    </>
  )
}

export default websiteservices
