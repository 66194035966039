import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import WebsiteMobilePage from "../../components/services/website-mobile-app-development/index"
const websitemobilePage = () => {
  return (
    <Layout>
      <Seo
        title="Services - Website &amp; Mobile App Development"
        desc=""
        pathname=""
        keywords=""
      />
      <WebsiteMobilePage />
    </Layout>
  )
}

export default websitemobilePage
